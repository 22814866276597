.unread-indicator {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff4d4d; /* Red color */
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.icon {
  position: fixed;
  bottom: 100px;
  right: 50px;
  margin-right: 30px;
  background-color: #ccd1da;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease; /* Adjust transition property */
  overflow: hidden;
  height: 100px; /* Set initial height */
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon:hover {
  background-color: #f6821e;
}

.chatbot-container {
  height: 400px;
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 400px;
  margin-right: 30px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease; /* Adjust transition property */
  overflow: hidden;
}

.chatbot-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f6821e;
  color: #ffffff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.chatbot-body {
  padding: 10px;
  overflow-y: auto;
  max-height: 350px;
}

.chat {
  margin-bottom: 10px;
}

.question-container {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 16px;
  width: 90%;
}

.content-container {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 16px;
  width: 100%;
}

.content-container:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

.answered-container {
  margin-bottom: 10px;
}

.chatbot-message {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  margin-right: 20%;
  font-size: medium;
}

.content-message {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: medium;
}

.image-container {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  margin: 0 auto; /* Center the container itself if it has a defined width */
}

.image-container img {
  max-width: 100%; /* Ensure the image doesn't overflow the container */
  height: auto; /* Maintain the aspect ratio */
}

.user-message {
  background-color: #49a3f1;
  color: #ffffff;
  padding: 10px;
  border-radius: 16px;
  margin-left: 20%;
  font-size: medium;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap;
  justify-content: space-between; */
}

.option {
  background-color: #49a3f1;
  color: #ffffff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 16px;
  cursor: pointer;
  width: 75%;
  text-align: center;
  font-size: medium;
}

.option:hover {
  background-color: #0056b3;
}

.user-input {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: medium;
  opacity: 0.8;
}

/* Input field */
.user-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin-right: 5px;
}

/* Submit button */
.submit-button {
  background-color: #49a3f1;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 10px 12px;
  align-items: center;
  cursor: pointer;
}

.content-title {
  font-size: 18px; /* Adjust the font size as needed */
  font-weight: bold; /* Makes the title bold */
  color: #0056b3; /* Adjust the color as needed */
  margin-bottom: 10px; /* Space between the title and the image */
  text-align: center;
}

.content-title a {
  text-decoration: none; /* Removes underline from the link */
  color: inherit; /* Inherits the color of the parent element */
}

.content-title a:hover {
  color: #007bff; /* Changes color on hover */
}

.answered .options {
  display: none;
}

.introductory-message {
  position: fixed;
  bottom: 210px; 
  right: 130px;
  width: 400px;
  background-color: #ffffff;
  color: #000;
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  font-size: medium;
  font-weight: 500;
  text-align: center;
  z-index: 1000;
}

.introductory-message p {
  margin: 0;
  padding: 10px;
  padding-left: 30px;
}

.intro-hr {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1); 
  margin: 10px 0; 
}

.introductory-message .close-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  transition: transform 0.3s, color 0.3s; 
}

.introductory-message .close-icon:hover {
  transform: scale(1.2); 
  color: #f6821e;
}

.intro-chat {
  background-color: #fff;
  color: #49a3f1;
  padding: 5px;
  margin-top: 5px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  font-size:larger;
  font-weight: 500;
}

.intro-chat:hover {
 color: #0056b3;
}