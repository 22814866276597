[data-amplify-form]{
  display: flex;
  flex-direction: column-reverse;
}

.amplify-flex.federated-sign-in-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse !important;
}


[data-amplify-authenticator] {
  --amplify-components-tabs-item-active-color: "black";
  --amplify-components-tabs-item-active-background-color: "white";
  --amplify-components-tabs-item-focus-color: "black";
  --amplify-components-tabs-item-focus-background-color:"white";
  
}

.amplify-tabs-item[data-state=active] {  
  color:rgb(4, 125, 149);
  border-color: rgb(255, 252, 253);
  background-color: rgb(255, 252, 253);
  transition-property: none ;
}

.amplify-tabs-item[data-state=inactive] {
  color:rgb(255, 255, 255);
  border-color: rgb(4, 125, 149);
  background-color: rgb(4, 125, 149);
  transition-property: none;
}
