/* SubscriptionModal.css */
.subscription-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.subscription-modal.open {
  display: flex;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.subscription-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.option:hover {
  border-color: #007bff;
}

.option input {
  display: none;
}

.option label {
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
}

.option span {
  color: #007bff;
  font-size:xx-large;
  margin-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
}

.option.selected {
  border-color: #007bff;
  border-radius: 15px;
  border-width: 5px;
}

.save {
  font-size:x-large;
  font-weight: bold;
  color:#fff;
  background-color: #007bff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: -12px;
}

.month {
  margin-top: 30px;
}

.month.selected{
  margin-top: 20px;
}

.modal-content button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.modal-content button + button {
  margin-left: 10px;
}